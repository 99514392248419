import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Particle from "../Particle";
import Aboutcard from "./AboutCard";
import AboutWheel from "./AboutWheel"
import Leviton from "../../Assets/Images/leviton-logo.svg";
// import bioPic from "../../Assets/Images/sandieWorking.jpeg";

function About() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "center", paddingTop: "0px", paddingBottom: "0px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
          >
            <h1 style={{ fontSize: "4.1em", paddingTop: "0px", paddingBottom: "0px" }}>
              Get to Know The <strong className="purple">Boss</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ marginTop: "55px", paddingTop: "10px", paddingBottom: "10px", height: "73vh" }}
            className="about-img"
          >
            {/* <img src={bioPic} alt="about" className="img-fluid" /> */}
            <AboutWheel />
          </Col>
          <h1 className="brands-heading">
          <strong className="purple">Brands</strong> I like
          <span> <image
          url={Leviton}
          />
          </span>
        </h1>
        </Row>

        

      </Container>
    </Container>
  );
}

export default About;
