import React from "react";
import Card from "react-bootstrap/Card";
// import { ImPointRight } from "react-icons/im";
import { FcFlashOn } from "react-icons/fc";
// import Bullet from "./AC-bullet.png";
// ~~~~~~~~ This needs to be added to the about page as another card "About Card 2" 
// My field of Interest's are;
// <i>
//   <b className="purple">Conservative Energy Systems </b> and
//   also in areas related to{" "}
//   <b className="purple">
//     Smart Home Electrical Panels to go along with all of the other smart devices that we enjoy.
//   </b>
// </i>
// <br />
// Whenever possible, I also apply my passion for custom electrical Installations
// <b className="purple">Like a pelton wheel to power the landsccape lighting. </b> and
// <i>
//   <b className="purple">
//     {" "}
//     Solar powered water features.
//   </b>
// </i>

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p className= 'about-body' style={{ textAlign: "justify" }}>
            Hello my name is <span className="purple">Sandra, </span>
            I was born and raised in the <span className="purple"> Snohomish County area.</span>
            <br />We are a Woman, locally owned and operated, Residential Electrical Contracting company. 
            <br />Not only do I work in the field, I take care of customers directly. 
            <br /> Nothing is more important to me than quality workmanship while still being affordable. 
            <br />I am supported by a Master Electrician who has 20+ years in the trade.
            <br />He knows how to get the job done, and will go the extra mile to make sure everything is done properly and up to code.
            <br/>Give us a call with any questions, we will be happy to help in any way we can.
            <br/>Or ask us to come out for a free in-home estimate on any project you are in need of having done,
            <br/>or just something on your wish list.          
          </p>
          <ul>
            <li className="about-activity">
            <FcFlashOn /> I enjoy spending time with my horses!
            </li>
            <li className="about-activity">
            <FcFlashOn /> Life isnt life without a few adventures throughout the year!
            </li>
            <li className="about-activity">
            <FcFlashOn />Relaxing around a warm fire is one of my happy places!
            </li>
          </ul>

          <p className= 'about-activity' style={{ color: "rgba(180,104,204,255)" }}>
            "Get inspired and lets build something that will last!"{" "}
          </p>
          <footer className="blockquote-footer">Sandi</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
