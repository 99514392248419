// Adjust the type to be centered more along the right hand side

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import homeLogo from "../../Assets/SS_ICON.jpg";
// import Particle from "../Particle";
// import Home2 from "./Home2";
import Type from "./Type";

function Home() {
  return (
      <Container fluid className="home-section" id="home">
        {/* <Particle /> */}
        <Container className="home-content">
          <Row>
            <Col md={8} className="home-header">
              <h1 className="heading">
                Hello there!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                From Short Stop Electrical LLC<br/>
                <strong className="main-name">425-495-7161</strong>
              </h1>
              <p className="home-body">
              Have an electrical issue like lights or plugs not working, maybe a breaker that keeps tripping,
              or cant figure out what that switch does… <span role= "img" className= "emoji" aria-labelledby="wave" description= "Questioning emoji.">🤷‍♂️</span>
              <br />Give us a call, we have a
              <i>
                <b className="purple"> Master Electrician </b>
              </i> on standby ready
              to find a solution!
              <br />
             
            </p>
              
            </Col>
            <Col md = {4} className= "home-type">
            <div>
                <Type />
              </div>
            </Col>

            {/* <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col> */}
          </Row>
        </Container>
      </Container>
  );
}

export default Home;
